// core
import React, { useMemo } from 'react';

// libraries
import { Route, Switch } from 'react-router-dom';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';

// partials
import { AppRouter } from './AppRouter';
import { XmlOutput } from './pages/XmlOutput';
import { PrintCar } from './pages/PrintCar';

export const App = () => {
  const gtmParams = useMemo(() => ({ id: process.env.REACT_APP_GTM_KEY }), []);

  return (
    <GTMProvider state={gtmParams}>
      <Switch>
        <Route
          exact
          path="/autobazar-eu.json"
          render={(props) => <XmlOutput type="AUTOBAZAREU" {...props} />}
        />

        <Route
          exact
          path="/autobazar-sk.xml"
          render={(props) => <XmlOutput type="AUTOBAZARSK" {...props} />}
        />

        <Route
          exact
          path="/sitemap.xml"
          render={(props) => <XmlOutput type="SITEMAP" {...props} />}
        />
        <Route path="/tlac/:slug" component={PrintCar} />

        <Route component={AppRouter} />
      </Switch>
    </GTMProvider>
  );
};
